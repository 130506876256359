<template>
  <div :class="{'used-coupon': item.use_flg === 1}">
  <v-card class="mx-auto" max-width="400" raised @click="routerBindTo(router_param)">
    <v-img v-if="item.image_url" height="224" :src="item.image_url" lazy-src="@/assets/coupon_img.jpg" class="noleftrounded">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-img v-else src="@/assets/coupon_img.jpg" lazy-src="@/assets/coupon_img.jpg" class="noleftrounded" height="224">
    </v-img>

    <div class="chips" v-if="item.distribution_name && item.distribution_type == 2">
      {{item.distribution_name}}
    </div>

    <div class="chips birth" v-if="item.distribution_name && item.distribution_type == 3">
      {{item.distribution_name}}
    </div>

    <v-card-title class="coupontitle">{{item.title}}</v-card-title>

    <v-card-text class="coupondates">
      <p>有効期限：{{item.disp_date}}</p>
      <p>対象店舗：{{item.shop_names}}</p>
    </v-card-text>

    <div class="view-unread-box" v-if="item.is_read === 0">
      未読
    </div>
    <div class="view-used-box" v-if="item.use_flg === 1">
      使用済み
    </div>

    <v-overlay class="" :value="used" absolute opacity="0.7" v-if="item.use_flg === 1">
    </v-overlay>

  </v-card>
  </div>
</template>

<script>
export default {
  props: {
    router_param: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },
}
</script>

<style scoped>
/* vue css override */
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
	border-radius: 20px;
	margin-bottom: 40px;
}

/* original css */

.chips {
	position: absolute;
	top: 212px;
	left: 12px;
	z-index: 50;
	padding: 3px 10px;
	color: #fff;
	background-color: #EB762F;
	font-size: 10px;
	transform: scale(0.9);
	transform-origin: left center;
	border-radius: 20px;
}
.chips.birth {
	background-color: #F26474;
}

.view-unread-box {
	position: absolute;
	bottom: 12px;
	right: 12px;
	z-index: 50;
	padding: 2px 15px;
	color: #fff;
	background-color: #9D9D9D;
	font-size: 10px;
	transform: scale(0.9);
	transform-origin: left center;
	border-radius: 5px !important;
}

.view-used-box {
	position: absolute;
	bottom: 12px;
	right: 12px;
	z-index: 50;
	padding: 2px 15px;
	color: #fff;
	background-color: #333;
	font-size: 10px;
	transform: scale(0.9);
	transform-origin: left center;
	border-radius: 5px !important;
}

.list {
	margin: 12px 0 0;
}

.coupontitle {
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

.coupondates p {
	font-weight: 500;
	font-size: 10px;
	line-height: 13px;
	margin-bottom: 5px;
	color: #9d9d9d;
	transform: scale(0.9);
	transform-origin: left center;
}

.used-coupon {
  background-color: #ddd;
  border-radius: 15px;
}
</style>
