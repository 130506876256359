<template>
  <v-content class="coupon">

    <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'coupon_shops', query: { feature_id: 'coupon_list'}}" />

    <div class="list pa-4" v-if="data">
      <div v-for="item in data" :key="item.id">
        <Row :item="item" :router_param="{ name: 'coupon_detail', query: { id: item.id}}"></Row>
      </div>
      <div class="text-center fontsize13 pt-2 px-4 pb-4" v-if="data === null || data.length === 0">
        <p>利用できるクーポンはありません</p>
      </div>
    </div>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
      <div slot="spinner" class="pt-2"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>

  </v-content>
</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
import Row from '@/views/coupon/row.vue'
import InfiniteLoading from 'vue-infinite-loading';
export default {
  components: {
		SelectedShop,
    InfiniteLoading,
    Row
  },
  data: () => ({
    data: null,
    result: [],
    page: 1,
    selected_shop: '全て',
    shop_id: null,
  }),
  created() {
    this.init = this.storageGet('*')
    this.shop_id = this.getUrlGetParam('shop_id');
    this.selected_shop = this.getUrlGetParam('selected_shop')
  },
  methods: {
    async infiniteHandler() {
      var params = {
        'page': this.page
      }

      if (this.shop_id) {
        params['shop_id'] = this.shop_id
      }

      var reqest_params = { params: params}
      var res = await this.apiCall('/user/coupons', reqest_params)
      if (res.data) {
        if (this.page == 1) {
          this.$set(this, 'result', res)
          this.data = res.data
        } else {
          this.data = this.data.concat(res.data);
        }
      }

      if (res.data && res.data.length > 0) {
        this.$refs.infiniteLoading.stateChanger.loaded()
      } else {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
      this.page = this.page + 1
    },
  }
}
</script>

<style scoped>
/* vue css override */
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 20px;
  margin-bottom: 40px;
}

/* original css */

.chips {
  position: absolute;
  top: 212px;
  left: 12px;
  z-index: 50;
  padding: 3px 10px;
  color: #fff;
  background-color: #EB762F;
  font-size: 10px;
  transform: scale(0.9);
  transform-origin: left center;
  border-radius: 20px;
}

.list {
  margin: 12px 0 0;
}

.coupondates p {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 5px;
  color: #9d9d9d;
  transform: scale(0.9);
  transform-origin: left center;
}

.v-application {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
